<template>
  <div>
    <b-modal ref="modal" centered size="lg" title="Status" cancel-title="Fechar">
      <b-alert show variant="info">
        <div class="alert-body">Você pode criar novos status ao <b-link @click="addPost">criar um post</b-link>.</div>
      </b-alert>

      <b-table responsive small :fields="fields" :items="items">
        <template #cell(actions)="data">
          <b-button size="sm" variant="danger" :disabled="loading" class="float-right" @click="drop(data.item)">
            <b-icon icon="trash" />
          </b-button>
        </template>
      </b-table>
    </b-modal>

    <modal-confirm ref="modalConfirm" />
  </div>
</template>

<script>
import { BModal, BTable, BButton, BIcon, BAlert, BLink } from 'bootstrap-vue'

import ModalConfirm from '@/components/ModalConfirm.vue'
import BlogService from '@/services/BlogService'
import toast from '@/mixins/toast'

export default {
  components: {
    ModalConfirm,
    BButton,
    BAlert,
    BModal,
    BTable,
    BLink,
    BIcon,
  },

  mixins: [toast],

  data() {
    return {
      items: [],
      loading: false,
      itemToDrop: null,
      fields: [
        { key: 'name', label: 'Nome' },
        { key: 'actions', label: '' },
      ],
    }
  },

  methods: {
    show() {
      this.loadItems()
      this.$refs.modal.show()
    },

    drop(item = null) {
      this.$refs.modalConfirm.show('Ao excluir o status, todos os posts relacionado à ele serão excluídos.', async () => {
        this.loading = true
        try {
          await BlogService.delStatus(item.id)
          const msg = 'Excluido com sucesso'
          this.$refs.modal.hide()
          this.loading = false
          this.msgSuccess(msg)
          this.$emit('reload')
        } catch (e) {
          this.msgError(e.message)
          this.loading = false
        }
      })
    },

    addPost() {
      this.$emit('addPost')
      this.$refs.modal.hide()
    },

    async loadItems() {
      this.items = (await BlogService.getStatusList()).data.result.items.map(m => m.blogStatus)
    },
  },
}
</script>
