<template>
  <b-pagination :total-rows="total" :value="page" :per-page="perPage" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="goto">
    <template #prev-text>
      <feather-icon icon="ChevronLeftIcon" size="18" />
    </template>
    <template #next-text>
      <feather-icon icon="ChevronRightIcon" size="18" />
    </template>
  </b-pagination>
</template>

<script>
import { BPagination } from 'bootstrap-vue'

export default {
  components: { BPagination },

  props: {
    paginate: null,
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 20,
    },
  },

  methods: {
    goto(page) {
      this.$props.paginate({ page })
    },
  },
}
</script>
