<template>
  <b-modal centered ref="modal" size="lg" title="Cadastro de Blog" ok-title="Salvar" ok-variant="success" cancel-title="Cancelar" @ok="save" @cancel="cancel" style="z-index: 9999 !important">
    <loader v-if="loading" style="z-index: 99999;"></loader>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="4">
          <label>Categoria</label>

          <validation-provider #default="{ errors }" name="Categoria" rules="required">
            <b-input-group>
              <b-input-group-prepend>
                <b-button variant="outline-primary" size="sm" @click="toggleCateg" :disabled="!hasCategories" v-if="dataCategValue.length < 1">
                  <b-icon :icon="toggleCategState ? 'plus' : 'view-list'" />
                </b-button>
                <b-button variant="success" size="sm" @click="saveCategory" v-if="!toggleCategState && dataCategValue.length > 0" :disabled="loading">
                  <b-icon icon="check" />
                </b-button>
              </b-input-group-prepend>
              <b-form-select ref="selectCateg" v-if="toggleCategState" v-model="data.blogCategoryId" :options="categoryOptions" :state="errors.length > 0 ? false : null" />
              <b-form-input v-else placeholder="Nova categoria" v-model="dataCategValue" :state="errors.length > 0 ? false : null" />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="8">
          <label>Título</label>
          <validation-provider #default="{ errors }" name="Título" rules="required">
            <b-form-input v-model="data.title" placeholder="Título" :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col cols="4">
          <label>Data de Publicação</label>
          <validation-provider #default="{ errors }" name="Data de Publicação" rules="required">
            <b-form-datepicker
              v-model="data.releaseDate"
              :min="minDate"
              locale="br"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :state="errorDate.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <label>Autor</label>
          <validation-provider #default="{ errors }" name="Nome do Autor" rules="required">
            <b-form-input placeholder="Nome do autor" v-model="data.authorName" :state="errors.length > 0 ? false : null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col cols="4">
          <label>Status</label>
          <b-input-group>
            <b-input-group-prepend>
              <b-button variant="outline-primary" size="sm" @click="toggleStatus" :disabled="!hasStatuses" v-if="dataStatusValue.length < 1">
                <b-icon :icon="toggleStatusState ? 'plus' : 'view-list'" />
              </b-button>
              <b-button variant="success" size="sm" @click="saveStatus" v-if="!toggleStatusState && dataStatusValue.length > 0" :disabled="loading">
                <b-icon icon="check" />
              </b-button>
            </b-input-group-prepend>
            <b-form-select v-if="toggleStatusState" v-model="data.blogStatusId" :options="statusOptions" />
            <b-form-input v-else placeholder="Novo status" v-model="dataStatusValue" />
          </b-input-group>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <label>Conteúdo</label>
          <validation-provider>
            <text-editor ref="editor" :text="data.contentBody" :state="errorContent.length > 0 ? false : null" @change="checkContent" />
            <small class="text-danger">{{ errorContent }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col>
          <label>Imagem de capa</label>
          <validation-provider>
            <b-form-file v-model="dataCoverPhotoFile" accept="image/*" @change="readFile" :state="errorFile.length > 0 ? false : null" />
            <small class="text-danger">{{ errorFile }}</small>
          </validation-provider>
          <div :style="[coverStyle, { backgroundImage: `url(${dataCoverPhoto})` }]" class="mt-1"></div>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BRow, BCol, BModal, BButton, BFormFile, BFormInput, BFormSelect, BFormDatepicker, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import BlogService from '@/services/BlogService'
import TextEditor from '@/views/TextEditor.vue'
import toast from '@/mixins/toast'
import Loader from '@/components/Loader.vue'

export default {
  mixins: [toast],

  components: { BRow, BCol, BButton, BModal, BFormFile, BFormInput, BFormSelect, BFormDatepicker, TextEditor, BInputGroup, BInputGroupPrepend, ValidationProvider, ValidationObserver, Loader },

  props: ['data'],

  data() {
    return {
      email,
      required,
      errorDate: '',
      errorFile: '',
      loading: false,
      errorContent: '',
      statusOptions: [],
      dataCategValue: '',
      dataStatusValue: '',
      minDate: new Date(),
      categoryOptions: [],
      dataCoverPhoto: null,
      toggleCategState: true,
      toggleStatusState: true,
      dataCoverPhotoFile: null,
      coverStyle: { backgroundPosition: 'center', backgroundSize: 'cover', height: '300px', borderRadius: '6px', border: '1px solid #999' },
    }
  },

  computed: {
    hasCategories() {
      return this.categoryOptions.length > 0
    },

    hasStatuses() {
      return this.statusOptions.length > 0
    },
  },

  methods: {
    checkContent() {
      this.errorContent = this.$refs.editor.content.length < 1 ? 'Conteúdo inválido' : ''
    },

    async loadCategories() {
      try {
        const list = (await BlogService.getCategoryList()).data.result.items
        this.categoryOptions = list.map(p => ({ text: p.blogCategory.name, value: p.blogCategory.id }))
      } catch (e) {
        this.msgError(e.message)
      }
    },

    async loadStatuses() {
      try {
        const list = (await BlogService.getStatusList()).data.result.items
        this.statusOptions = list.map(p => ({ text: p.blogStatus.name, value: p.blogStatus.id }))
      } catch (e) {
        this.msgError(e.message)
      }
    },

    async save(ev) {
      ev.preventDefault()
      try {
        const { editor } = this.$refs
        const { content } = editor
        this.errorContent = content === undefined || content.length < 1 ? 'Conteúdo inválido' : ''
        this.errorFile = this.dataCoverPhotoFile === null && this.data.image === undefined ? 'A imagem de capa é obrigatório' : ''
        this.$refs.simpleRules.validate().then(async success => {
          if (this.errorContent.length > 1 || this.errorFile.length > 1) {
            return
          }
          if (success) {
            const file = this.dataCoverPhotoFile
            if (file) {
              this.data.coverPhoto = {}
              const [, bytes] = this.dataCoverPhoto.split(',')
              this.data.coverPhoto.description = file.name
              this.data.coverPhoto.dataType = file.type
              this.data.coverPhoto.bytes = bytes
            }

            this.loading = true
            this.data.contentBody = this.$refs.editor.content
            await BlogService.setItem(this.data).then(() => this.msgSuccess('Salvo com sucesso'),
              error => {
                this.msgError(error.response.data.error.message)
              })
            this.loading = false
            this.$emit('reload')

            this.$refs.modal.hide()
          }
        })
      } catch (e) {
        this.msgError(e.message)
      }
    },

    async cancel() {
      this.$emit('reload')
      this.prepare()
      this.$refs.modal.hide()
    },

    async saveCategory() {
      try {
        this.loading = true
        await BlogService.setCategory({ name: this.dataCategValue })
        await this.loadCategories()
        this.dataCategValue = ''
        this.loading = false
        this.toggleCateg()
      } catch (e) {
        this.msgError(e.message)
      }
    },

    async saveStatus() {
      try {
        this.loading = true
        await BlogService.setStatus({ name: this.dataStatusValue })
        await this.loadStatuses()
        this.dataStatusValue = ''
        this.loading = false
        this.toggleStatus()
      } catch (e) {
        this.msgError(e.message)
      }
    },

    async prepare() {
      await this.loadStatuses()
      await this.loadCategories()
      this.errorContent = ''
      this.errorDate = ''
      this.errorFile = ''
      this.toggleStatusState = this.hasStatuses
      this.toggleCategState = this.hasCategories
      if (this.hasStatuses && this.data.blogStatusId == null) this.data.blogStatusId = this.statusOptions[0].value
      if (this.hasCategories && this.data.blogCategoryId == null) this.data.blogCategoryId = this.categoryOptions[0].value
      if (this.data.coverPhoto) this.dataCoverPhoto = `data:${this.data.coverPhoto.dataType};base64,${this.data.coverPhoto.bytes}`
      else this.dataCoverPhoto = null
      this.data.coverPhoto = null
    },

    readFile(ev) {
      if (ev.target.files.length) {
        const file = ev.target.files[0]
        if (file.size > 1024 * 1024) {
          ev.preventDefault()
          const msg = 'O arquivo não pode ser maior que 1MB'
          this.errorFile = msg
          this.msgError(msg)
          return
        }

        const reader = new FileReader()
        reader.readAsDataURL(file)
        this.errorFile = ''

        reader.onload = () => {
          this.dataCoverPhoto = reader.result
          this.data.newImage = true
        }

        reader.onerror = error => {
          this.msgError(error)
        }
      }
    },

    toggleCateg() {
      this.toggleCategState = !this.toggleCategState
    },

    toggleStatus() {
      this.toggleStatusState = !this.toggleStatusState
    },

    async show() {
      this.data = {}
      await this.prepare()
      this.$refs.modal.show()
    },

    reset() {
      this.data = {}
    },
  },
}
</script>
