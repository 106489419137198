<template>
  <div>
    <loader v-if="loading"></loader>
    <b-button size="sm" variant="success" @click="openForm()">Criar Post</b-button>
    <b-button size="sm" variant="info" @click="listStatus()" class="ml-1">Status</b-button>
    <b-button size="sm" variant="primary" @click="listCateg()" class="ml-1">Categorias</b-button>
   
    <vue-good-table
      id="table_blog_list"
      :columns="columns"
      :rows="blogListItems"
      :rtl="direction"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }">
       <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field === 'title'">
          <span>{{ props.row.title }}</span>
        </span>
        <span v-if="props.column.field === 'authorName'">
          <span>{{ props.row.authorName }}</span>
        </span>
        <span v-if="props.column.field === 'blogStatusName'">
          <span>
             <b-badge :variant="props.row.blogStatusName.toLowerCase() == 'publicado' ? 'success' : 'warning'">
              {{ props.row.blogStatusName }}
            </b-badge>
          </span>
        </span>
        <span v-if="props.column.field === 'blogCategoryName'">
          <span>{{ props.row.blogCategoryName }}</span>
        </span>
        <span>
          <span v-if="props.column.field === 'actions'">
            <b-link v-b-tooltip.hover.v-secondary title="Editar Post" @click="edit(props.row)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
            </b-link>
            <b-link v-b-tooltip.hover.v-secondary style="margin-left: 5%" title="Excluir Post" @click="drop(props.row)">
              <feather-icon icon="XIcon" class="mr-50" />
            </b-link>
          </span>
        </span>
       </template><template slot="emptystate" styleClass="table_empty_data">
        <div class="table_empty_data">
          Sem dados
        </div>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span> Mostrando </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                value => props.perPageChanged({ currentPerPage: value })
              " />
            <span> de {{ blogListItemsTotal }} Resultados </span>
          </div>
          <div>
            <pagination :total="blogListItemsTotal" :perPage="perPage" :paginate="loadBlogItems" />
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- MODAL FORM -->
    <modal-form ref="modal" :data="form" @reload="loadBlogItems" />

    <!-- MODAL CATEGORY -->
    <modal-categories ref="modalCateg" @addPost="openForm" />

    <!-- MODAL STATUS -->
    <modal-status ref="modalStatus" @addPost="openForm" />
  </div>
</template>

<script>
import { BTable, BButton, BBadge, BAlert, BFormSelect, BLink } from 'bootstrap-vue'

import BlogService from '@/services/BlogService'
import toast from '@/mixins/toast'
import Pagination from '@/components/Pagination.vue'
import ModalForm from './components/ModalForm.vue'
import ModalCategories from './components/ModalCategories.vue'
import ModalStatus from './components/ModalStatus.vue'
import Loader from '../../../components/Loader.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BTable,
    BButton,
    BLink,
    BFormSelect,
    BAlert,
    BBadge,
    ModalForm,
    Pagination,
    ModalCategories,
    ModalStatus,
    Loader,
    VueGoodTable,
  },
  mixins: [toast],
  created() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false)
  },
  destroyed() {
    this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden)
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },

  data() {
    return {
      pageLength: 10,
      blogListItems: [],
      blogListItemsTotal: 0,
      loading: false,
      perPage: 10,
      form: { blogCategoryId: null, blogStatusId: null, title: '', authorName: '', releaseDate: '', contentBody: '', coverPhoto: null },
      formReset: { blogCategoryId: null, blogStatusId: null, title: '', authorName: '', releaseDate: '', contentBody: '', coverPhoto: null },
      columns: [
        { field: 'title', label: 'Título' },
        { field: 'authorName', label: 'Autor' },
        { field: 'blogStatusName', label: 'Status' },
        { field: 'blogCategoryName', label: 'Category' },
        { field: 'actions', label: '' },
      ],
    }
  },

  methods: {
    async loadBlogItems(p = null) {
      this.form = {}
      this.$refs.modal.reset()
      this.loading = true
      const page = p ? p.page - 1 : 0
      try {
        const list = (await BlogService.getItemList(`SkipCount=${page > 0 ? page * this.perPage : 0}`)).data.result
        this.blogListItemsTotal = list.totalCount
        this.blogListItems = list.items.map(m => ({
          blogCategoryName: m.blogCategoryName,
          blogStatusName: m.blogStatusName,
          ...m.blogItem,
        }))
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },

    async edit(item) {
      this.form = {}
      this.loading = true
      const file = (await BlogService.getBinary(item.coverPhotoId)).data.result.appBinaryObjects
      const data = (await BlogService.getItem(item.id)).data.result.blogItem
      this.$refs.modal.show()
      data.coverPhoto = file
      this.form = data
      this.loading = false
    },

    async drop(item) {
      this.confirmDelete(item.title).then(async value => {
        if (value) {
          try {
            this.msgLoading(`Excluindo post "${item.title}"...`)
            const msg = 'Item excluído com  sucesso'
            await BlogService.delItem(item.id)
            this.msgSuccess(msg)
            this.loadBlogItems()
          } catch (e) {
            this.msgError(e.message)
          }
        }
      })
    },

    openForm() {
      this.form = {}
      this.$refs.modal.reset()
      this.$refs.modal.show()
    },

    listCateg() {
      this.$refs.modalCateg.show()
    },

    listStatus() {
      this.$refs.modalStatus.show()
    },
  },

  mounted() {
    this.loadBlogItems()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

#table_blog_list th { display: none;}
#table_blog_list thead { display: none;}

#table_blog_list .vgt-table {
  background: none;
}
#table_blog_list a { color: #6e6b7b !important }
#table_blog_list a:hover { color: var(--color) !important }

#table_blog_list table tbody td:first-child { max-width: 420px; min-width: 420px; }
#table_blog_list table tbody td:nth-child(2) { max-width: 320px; min-width: 320px;  }
#table_blog_list table tbody td:nth-child(3) { max-width: 150px; min-width: 200px; }
#table_blog_list table tbody td:nth-child(4) { max-width: 250px; min-width: 220px; }
#table_blog_list table tbody td:nth-child(5) { max-width: 100px; min-width: 100px; float: right; margin-top: -58px; }

</style>